import React, { useState, useEffect } from "react";
import axios from "axios";
import RxnTable from "../layout/components/RxnTable";
import { Button, Modal, Form } from "react-bootstrap";
import { IconAddOutline } from "../layout/components/SVGs";
import toast from "react-hot-toast";


// TODO: Need to clean up this page

// Authentication Check
import useToken from "../authentication/token";
import { useNavigate } from "react-router-dom";

export default function Roles() {
  // Authentication Check
  const { token, role, verifyToken, kickOutGuest } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (role !== "admin") {
      navigate("/");
    }
    token ? verifyToken() : kickOutGuest();
  }, []);

  const [smartsList, setSmartsList] = useState({
    columns: [
      {
        label: "ID",
        field: "smarts_id",
      },
      {
        label: "SMARTS",
        field: "smarts_str",
      },
    ],
    data: [],
  });

  const [smarts, setSmarts] = useState({
    id: "",
    smarts_str: "",
    smarts_description: "",
  });
  const [loading, setLoading] = useState(false);

  const [showAddRole, setShowAddRole] = useState(false);
  const handleAddClose = () => setShowAddRole(false);
  const handleShowAdd = () => {
    setShowAddRole(true);
  };

  const [showEditRole, setShowEditRole] = useState(false);
  const handleEditClose = () => {
    setShowEditRole(false);
    setSmarts({ id: "", name: "", description: "" });
  };
  const handleShowEdit = (row) => {
    setShowEditRole(true);
    setSmarts({
      id: row.smarts_id,
      smarts: row.smarts_str,
      description: row.smarts_description,
    });
  };

  const [showDeleteSmarts, setShowDeleteSmarts] = useState(false);
  const handleDeleteClose = () => {
    setShowDeleteSmarts(false);
    setSmarts({ id: "", name: "", description: "" });
  };
  const handleShowDelete = (row) => {
    setShowDeleteSmarts(true);
    setSmarts({
      id: row.role_id,
      name: row.role_name,
    });
  };

  function getSmartsList() {
    axios({
      method: "GET",
      url: "/reactionClass/smartsList",
    })
      .then((res) => {
        setSmartsList({ ...smartsList, data: res.data.smarts_list });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmitCreate() {
    setLoading(true);
    let smartsData = {
      roleName: smarts.name,
      roleDescription: smarts.description,
    };
    console.log(smartsData);
    axios({
      method: "POST",
      url: "/admin/manage/roles/add",
      data: smartsData,
    })
      .then((res) => {
        getSmartsList();
        toast.success(res.data.msg);
        handleAddClose();
      })
      .catch((err) => {
        handleAddClose();
        toast.error(err.response.data.msg);
      });
    setLoading(false);
  }

  function handleSubmitEdit() {
    setLoading(true);
    let smartsData = {
      smartsId: smarts.id,
      smarts: smarts.smarts,
      smartsDescription: smarts.description,
    };
    axios({
      method: "POST",
      url: "/admin/manage/smarts/update",
      data: smartsData,
    })
      .then((res) => {
        getSmartsList();
        toast.success(res.data.msg);
        handleEditClose();
      })
      .catch((err) => {
        handleEditClose();
        toast.error(err.response.data.msg);
      });
    setLoading(false);
  }

  function handleSubmitDelete() {
    setLoading(true);
    let smartsData = {
      roleId: smarts.id,
    };
    axios({
      method: "POST",
      url: "/admin/manage/roles/delete",
      data: smartsData,
    })
      .then((res) => {
        getSmartsList();
        toast.success(res.data.msg);
        handleDeleteClose();
      })
      .catch((err) => {
        handleDeleteClose();
        toast.error(err.response.data.msg);
      });
    setLoading(false);
  }

  useEffect(() => {
    getSmartsList();
  }, []);

  return (
    <>
      <RxnTable
        striped
        bordered
        hover
        data={smartsList}
        token={token}
        actions={{
          add: false,
          addLogic: () => (
            <Button
              onClick={() => {
                handleShowAdd();
              }}
            >
              <IconAddOutline />
            </Button>
          ),
          edit: true,
          editLogic: (row) => {
            handleShowEdit(row);
          },
          delete: false,
          deleteLogic: (row) => {
            handleShowDelete(row);
          },
        }}
      ></RxnTable>

      {/* 
      ***
      Modal for creating roles
      ***
       */}
      <Modal
        size="xl"
        backdrop="static"
        show={showAddRole}
        onHide={handleAddClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter role name"
                onChange={(e) => {
                  setSmarts({ ...smarts, name: e.target.value });
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Role Description</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter role description"
                onChange={(e) =>
                  setSmarts({ ...smarts, description: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="primary"
            type="submit"
            onClick={() => handleSubmitCreate()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        backdrop="static"
        show={showEditRole}
        onHide={handleEditClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit SMARTS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
                <Form.Group>
                <Form.Label>SMARTS ID</Form.Label>
                <Form.Control
                    className="mb-3"
                    required
                    type="text"
                    value={smarts.id}
                    disabled
                ></Form.Control>
            </Form.Group>
              <Form.Label>SMARTS String</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter SMARTS string"
                value={smarts.smarts}
                onChange={(e) =>
                  setSmarts({ ...smarts, smarts: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>SMARTS Description</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter SMARTS description"
                value={smarts.description}
                onChange={(e) =>
                  setSmarts({ ...smarts, description: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="primary"
            type="submit"
            onClick={() => handleSubmitEdit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        backdrop="static"
        show={showDeleteSmarts}
        onHide={handleDeleteClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete SMARTS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete "{smarts.name}"
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="danger"
            type="submit"
            onClick={() => handleSubmitDelete()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
