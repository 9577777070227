import React, { useEffect, useState } from "react";
import { Button, Row, ButtonGroup } from "react-bootstrap";
import Base from "../layout/BaseTemplate";
import useToken from "../authentication/token";
import RxnRegistrations from "./ManageRxnRegistration";
import ManageReactions from "./ManageReactions";
import TagsList from "./ManageTags";
import Roles from "./ManageRoles";
import Users from "./ManageUsers";
import Smarts from "./ManageSmarts";
import Synthons from "./ManageSynthons";

export default function AdminLandingPage() {
  // Authentication Check
  const { token, verifyToken, kickOutGuest, role } = useToken();
  useEffect(() => {
    token && role === "admin" ? verifyToken() : kickOutGuest();
  }, []);

  const [tab, setTab] = useState(Number(localStorage.getItem("tab")) || 1);

  useEffect(() => {
    localStorage.setItem("tab", tab);
  }, [tab]);

  return (
    <Base>
      <Row className="centerText">
        <ButtonGroup>
          <Button
            id={1}
            active={tab === 1}
            onClick={() => {
              setTab(1);
            }}
          >
            Manage Reaction Registration
          </Button>
          <Button
            id={2}
            active={tab === 2}
            onClick={() => {
              setTab(2);
            }}
          >
            Manage Reactions
          </Button>
          <Button
            id={3}
            active={tab === 3}
            onClick={() => {
              setTab(3);
            }}
          >
            Manage Synthons
          </Button>
          <Button
            id={4}
            active={tab === 4}
            onClick={() => {
              setTab(4);
            }}
          >
            Manage Tags
          </Button>
          <Button
            id={5}
            active={tab === 5}
            onClick={() => {
              setTab(5);
            }}
          >
            Manage Smarts
          </Button>
          <Button
            id={6}
            active={tab === 6}
            onClick={() => {
              setTab(6);
            }}
          >
            Manage Roles
          </Button>
          <Button
            id={7}
            active={tab === 7}
            onClick={() => {
              setTab(7);
            }}
          >
            Manage Users
          </Button>
        </ButtonGroup>
      </Row>
      <br />
      {tab === 1 && <RxnRegistrations />}
      {tab === 2 && <ManageReactions />}
      {tab === 3 && <Synthons />}
      {tab === 4 && <TagsList />}
      {tab === 5 && <Smarts />}
      {tab === 6 && <Roles />}
      {tab === 7 && <Users />}
    </Base>
  );
}
