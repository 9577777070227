import React, { useEffect, useState } from "react";
import Base from "../layout/BaseTemplate";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Table, Card, Row, Col, Button, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft } from "../layout/components/SVGs";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export default function SynthonDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [synDetail, setSynDetail] = useState({
    synId: "",
    synName: "",
    synSmarts: "",
    synRxns: [],
    synInSMARTS: [],
    synExSMARTS: [],
  });

  function getSynDetail() {
    axios({
      method: "GET",
      url: "/reactionClass/synthon/" + id,
    }).then((res) => {
      setSynDetail({
        synId: res.data.synthon_id,
        synName: res.data.synthon_name,
        synSmarts: res.data.synthon_smarts,
        synRxns: res.data.rxn_list,
        synInSMARTS: res.data.inSMARTS,
        synExSMARTS: res.data.exSMARTS,
      });
      console.log(res.data);
    });
  }

  function DisplayImage() {
    const [imageSource, setImageSource] = useState(null);
    const localImageUrl = `/img/synthons/SYN-${id}.png`;
    const apiUrl = `https://smarts.plus/smartsview/download_rest?;depsymbols=0;vmode=0;textdesc=0;smartsheading=0;smarts=${encodeURIComponent(
      synDetail.synSmarts
    )}`;

    //Using img object, check whether img path is real by loading in the img
    const img = new Image();
    img.src = localImageUrl;

    //If img exist, then img src will the cached img
    img.onload = () => {
      setImageSource(localImageUrl);
    };

    //If no img in cache, then img src will be API call
    img.onerror = () => {
      setImageSource(apiUrl);
    };

    //return image
    return (
      <Zoom>
        <img width={500} src={imageSource} alt={`SYN-${id}.png`} />
      </Zoom>
    );
  }

  function renderRxns() {
    let result;
    if (synDetail.synRxns.length === 0) {
      result = (
        <tr>
          <td>N/A</td>
        </tr>
      );
    } else {
      result = synDetail.synRxns.map((value, index) => (
        <tr>
          <td>{value.rxn_id}</td>
          <td>{value.rxn_name}</td>
        </tr>
      ));
    }
    return result;
  }
  function renderRxnRules() {
    // display inclusion SMARTS and exclusion SMARTS
    let result;
    result = (
      <Card>
        <Card.Header>Reaction Rules</Card.Header>
        <Card.Body>
        <Row>
          <Col>
            <Card.Title>Inclusion SMARTS</Card.Title>
            <ListGroup variant="flush">
              {synDetail.synInSMARTS.map((insyn, index) => {
                return (
                  <ListGroup.Item key={"inSmarts" + index}>
                    {insyn}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
          <Col>
            <Card.Title>Exclusion SMARTS</Card.Title>
            <ListGroup variant="flush">
              {synDetail.synExSMARTS.map((exsyn, index) => {
                return (
                  <ListGroup.Item key={"exSmarts" + index}>
                    {exsyn}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
          </Row>
        </Card.Body>
        </Card>
    );
    return result;
  }
  

  useEffect(() => {
    getSynDetail();
  }, []);

  return (
    <Base>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Button
                className="mt-2"
                variant="outline-primary"
                onClick={() => {
                  navigate("/synthons");
                }}
              >
                <IconArrowLeft />
              </Button>
            </Col>
            <Col className="centerText mt-2">
              <h2>{synDetail.synName}</h2>
            </Col>
            <Col></Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>ID</th>
                <td>{synDetail.synId}</td>
              </tr>
              <tr>
                <th>SMARTS</th>
                <td>{synDetail.synSmarts}</td>
              </tr>
              <tr>
                <th>Reactions</th>
                <td>
                  <Table striped bordered hover>
                    <tbody>{renderRxns()}</tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
          <Row>
            <Col className="centerText">
              <DisplayImage />
            </Col>
          </Row>
          <Row>
            <Col>
              {renderRxnRules()}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Base>
  );
}
