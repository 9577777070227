import { useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function VerifyEmail() {
    const { token } = useParams();
    const navigate = useNavigate();
    console.log(token);

    function checkToken() {
        
        axios({
            method: "POST",
            url: "/auth/email_verification",
            data: { token : token },
        }).then((res) => {
            console.log(res);
            alert(res.data.msg);
            setTimeout(() => {
                navigate("/login");
            }, 2000);
        })
        .catch((err) => {
            console.log(err);
            toast.error(err.msg);
        });
    }

    useEffect(() => {
        checkToken();
    }, []);
}
    