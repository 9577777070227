import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Modal, Form, Card, Col, Row } from "react-bootstrap";
import RxnTable from "../layout/components/RxnTable";
import Select from "react-select";
import { IconDelete } from "../layout/components/SVGs";

export default function ManageReactions() {
  //
  //USE STATES
  //
  const [syn, setSyn] = useState([]);
  const [tags, setTags] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rxnStates, setRxnStates] = useState([]);

  const [rxn, setRxn] = useState({
    rxnName: "",
    rxnDescription: "",
    rxnSmarts: "",
    rxnSmartsDescription: "",
    rxnId: "",
    rxnState: "",
    rxnTags: [],
  });

  const [rxnList, setRxnList] = useState({
    columns: [
      {
        label: "RXN ID",
        field: "rxn_id",
      },
      {
        label: "RXN Name",
        field: "rxn_name",
      },
      {
        label: "Description (Product: Reagents)",
        field: "description",
      },
      {
        label: "RXN SMARTS",
        field: "smarts",
      },
      {
        label: "Reagent Count",
        field: "reagent_count",
      },
      {
        label: "Status",
        field: "status",
      },
    ],
    data: [],
  });

  //
  //FUNCTIONS
  //
  function getReactionStates() {
    return axios
      .get("/admin/manage/rxnStates")
      .then((res) => setRxnStates(res.data))
      .catch((error) => {
        console.error("Error fetching reaction states:", error);
        return [];
      });
  }

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = (row) => {
    getRxn(row.rxn_id);
    setShowEdit(true);
  };

  const handleCloseDelete = () => {
    setRxn({
      rxnName: "",
      rxnDescription: "",
      rxnSmarts: "",
      rxnSmartsDescription: "",
      rxnId: "",
      rxnState: "",
      rxnTags: [],
    });
    setShowDelete(false);
  };
  const handleShowDelete = (row) => {
    setRxn({
      rxnId: row.rxn_id,
      rxnName: row.rxn_name,
    });
    setShowDelete(true);
  };

  // Triggers every change in synthon form
  const synFormChange = (index, e) => {
    let data = [...syn];
    data[index][e.target.id] = e.target.value;
    setSyn(data);
  };

  function getTagList() {
    axios({
      method: "GET",
      url: "/reactionClass/tagList",
    }).then((res) => {
      let getTagLabels = [];
      for (let i = 0; i < res.data.tags.length; i++) {
        getTagLabels.push({
          label: res.data.tags[i].label,
          value: res.data.tags[i].value,
        });
      }
      setTags(getTagLabels);
    });
  }

  async function getReactions() {
    setLoading(true);
    await axios({
      method: "GET",
      url: "/admin/manage/reactions_list",
    })
      .then((res) => {
        setRxnList({ ...rxnList, data: res.data });
      })
      .catch((err) => console.log(err));
    setLoading(false);
  }

  function getRxn(id) {
    axios({
      method: "GET",
      url: "/reactionClass/reaction/" + id,
    }).then((res) => {
      setRxn({
        ...rxn,
        rxnId: res.data.rxn_id,
        rxnName: res.data.rxn_name,
        rxnDescription: res.data.description,
        rxnSmarts: res.data.smarts,
        rxnSmartsDescription: res.data.smarts_description,
        rxnState: res.data.rxn_state,
        rxnTags: res.data.tags,
      });

      let data = res.data.synthons;
      let newSyn = [];
      for (let index = 0; index < data.length; index++) {
        newSyn.push({
          synthon_name: data[index].synthon_name,
          synthon_id: data[index].synthon_id,
          synthon_inclusion_smarts: data[index].synthon_inclusion_smarts
            ? data[index].synthon_inclusion_smarts.join("\n")
            : "",
          synthon_exclusion_smarts: data[index].synthon_exclusion_smarts
            ? data[index].synthon_exclusion_smarts.join("\n")
            : "",
        });
      }
      setSyn(newSyn);
    });
  }
  function submitRxnUpdate() {
    let rxnData = {
      ...rxn,
      synthons: syn.map((synthon) => {
        return {
          ...synthon,
          exSmarts: synthon.synthon_exclusion_smarts.split("\n"),
          inSmarts: synthon.synthon_inclusion_smarts.split("\n"),
        };
      }),
    };
    console.log(rxnData);
    axios({
      method: "POST",
      url: "/admin/manage/reactions/update",
      data: rxnData,
    })
      .then((res) => {
        getReactions();
        alert(res.data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleDelete() {
    axios({
      method: "POST",
      url: "/admin/manage/reactions/delete",
      data: rxn.rxnId,
    })
      .then((res) => {
        getReactions();
        alert(res.data.msg);
        handleCloseEdit();
      })
      .catch((error) => {
        console.error("Error deleting reaction:", error);
        getReactions();
        handleCloseEdit();
      });
  }

  useEffect(() => {
    getReactionStates();
    getReactions();
    getTagList();
  }, []);

  return (
    <>
      <RxnTable
        striped
        bordered
        hover
        hasTags
        adminRxnCache
        tags={tags}
        data={rxnList}
        loading={loading}
        actions={{
          edit: true,
          editLogic: (row) => {
            handleShowEdit(row);
          },
          delete: true,
          deleteLogic: (row) => {
            handleShowDelete(row);
          },
        }}
      ></RxnTable>
      <Modal
        size="xl"
        backdrop="static"
        show={showEdit}
        onHide={handleCloseEdit}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h2">Reaction Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/*  */}
            {/*  */}
            {/* Reaction Form */}
            {/*  */}
            {/*  */}
            <Card>
              <Card.Header>
                <h3>{rxn.rxnId}</h3>
              </Card.Header>
              <Card.Body>
                <Form.Group controlId="rxnName">
                  <Form.Label>Reaction Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter reaction name"
                    value={rxn.rxnName}
                    onChange={(e) =>
                      setRxn({
                        ...rxn,
                        rxnName: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="rxnDescription">
                  <Form.Label>Reaction Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={rxn.rxnDescription}
                    onChange={(e) =>
                      setRxn({
                        ...rxn,
                        rxnDescription: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="rxnSmarts">
                  <Form.Label>Reaction Smarts</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={rxn.rxnSmarts}
                    onChange={(e) =>
                      setRxn({
                        ...rxn,
                        rxnSmarts: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="rxnSmartsDescription">
                  <Form.Label>Reaction Smarts Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={rxn.rxnSmartsDescription}
                    onChange={(e) =>
                      setRxn({
                        ...rxn,
                        rxnSmartsDescription: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="rxnState">
                  <Form.Label key={"reactionState"}>Reaction State</Form.Label>
                  <Form.Select
                    value={rxn.rxnState}
                    onChange={(e) =>
                      setRxn({
                        ...rxn,
                        rxnState: e.target.value,
                      })
                    }
                  >
                    {rxnStates.map((rxnState, index) => (
                      <option
                        key={"state" + index}
                        value={rxnState["state_id"]}
                      >
                        {rxnState["state_name"]}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="taggedRXNs">
                  <Form.Label>Add Reaction Tag</Form.Label>
                  <Select
                    value={rxn.rxnTags}
                    options={tags}
                    isMulti
                    isSearchable
                    className="mb-3"
                    placeholder="Select Tag"
                    onChange={(e) => {
                      setRxn({
                        ...rxn,
                        rxnTags: e,
                      });
                    }}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
            <br />

            {/*  */}
            {/*  */}
            {/* Synthon Form */}
            {/*  */}
            {/*  */}
            {syn.length >= 1 &&
              syn.map((input, index) => (
                <>
                  <Card>
                    <Card.Header>
                      <Row>
                        <Col className="leftText">
                          <h3>Synthon {index + 1}</h3>
                        </Col>
                        <Col></Col>
                        <Col className="rightText">
                          {syn.length > 1 && (
                            <Button
                              key={"deleteSyn"}
                              className=" m-1"
                              variant="outline-danger"
                              onClick={() => {
                                let tempSynArr = [...syn];
                                tempSynArr.splice(index, 1);
                                setSyn(tempSynArr);
                              }}
                            >
                              <IconDelete />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Form.Group className="mb-3" controlId={"synthon_name"}>
                        <Form.Label>Synthon Name</Form.Label>
                        <Form.Control
                          value={syn[index].synthon_name}
                          onChange={(e) => synFormChange(index, e)}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId={"synthon_inclusion_smarts"}
                      >
                        <Form.Label>Inclusion SMARTS</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          value={syn[index].synthon_inclusion_smarts}
                          onChange={(e) => synFormChange(index, e)}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId={"synthon_exclusion_smarts"}
                      >
                        <Form.Label>Exclusion SMARTS</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          value={syn[index].synthon_exclusion_smarts}
                          onChange={(e) => synFormChange(index, e)}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                </>
              ))}
          </Form>
          <div className="centerText">
            <Button
              onClick={() => {
                setSyn([
                  ...syn,
                  {
                    synthon_name: "",
                    synthon_inclusion_smarts: [],
                    synthon_exclusion_smarts: [],
                  },
                ]);
              }}
            >
              Add New Synthon
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Col className="rightText">
            <Button variant="primary" type="submit" onClick={submitRxnUpdate}>
              Save
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
      <Modal
        size="xl"
        backdrop="static"
        show={showDelete}
        onHide={handleCloseDelete}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Reaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            Are you sure you want to delete <b>{rxn.rxnName}</b>?
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="danger"
            type="submit"
            onClick={() => handleDelete()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
