import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import HomePage from "./homePage/HomePage";
import RegisterReactionForm from "./reactionPages/RegisterReaction";
import Reactions from "./reactionPages/Reactions";
import ReactionDetail from "./reactionPages/ReactionDetail";
import LoginForm from "./authentication/Login";
import RegisterForm from "./authentication/Register";
import VerifyEmail from "./authentication/VerifyEmail";
import ForgotPassword from "./authentication/ForgotPass";
import Synthons from "./synthons/Synthons";
import SynthonDetail from "./synthons/SynthonDetail";

// Admin manage pages
import RxnRegistrations from "./admin/ManageRxnRegistration";
import AdminLandingPage from "./admin/AdminLandingPage";
import ManageReactions from "./admin/ManageReactions";

import ManageTags from "./admin/ManageTags";
import ManageRoles from "./admin/ManageRoles";
import ManageUsers from "./admin/ManageUsers";
import ManageSmarts from "./admin/ManageSmarts";
import ManageSynthons from "./admin/ManageSynthons";

// Users pages
import UserRxnRegistrationList from "./admin/UserRxnRegistrationList";

import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/register_reaction",
    element: <RegisterReactionForm />,
  },
  {
    path: "/reactions",
    element: <Reactions />,
  },
  {
    path: "/login",
    element: <LoginForm />,
  },
  {
    path: "/register",
    element: <RegisterForm />,
  },
  {
    path: "/verifyEmail/:token",
    element: <VerifyEmail />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/reactions/:id",
    element: <ReactionDetail />,
  },
  {
    path: "/synthons",
    element: <Synthons />,
  },
  {
    path: "/synthons/:id",
    element: <SynthonDetail />,
  },
  {
    path: "/user/rxnRegistrations",
    element: <UserRxnRegistrationList />,
  },
  {
    path: "/admin",
    element: <AdminLandingPage />,
  },
  {
    path: "admin/manage/reactions",
    element: <ManageReactions />,
  },
  {
    path: "/admin/manage/rxnRegistrations",
    element: <RxnRegistrations />,
  },
  {
    path: "/admin/manage/tags",
    element: <ManageTags />,
  },
  {
    path: "/admin/manage/roles",
    element: <ManageRoles />,
  },
  {
    path: "/admin/manage/users",
    element: <ManageUsers />,
  },
  {
    path : "/admin/manage/smarts",
    element : <ManageSmarts />
  },
  {
    path : "/admin/manage/synthons",
    element : <ManageSynthons />
  }

]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <div>
    <RouterProvider router={router} />
  </div>
);
