import React, { useState, useEffect } from "react";
import axios from "axios";
import RxnTable from "../layout/components/RxnTable";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";

// Authentication Check
import useToken from "../authentication/token";
import { useNavigate } from "react-router-dom";

export default function Users() {
  // Authentication Check
  const { token, verifyToken, kickOutGuest, role } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (role !== "admin") {
      navigate("/");
    }
    token ? verifyToken() : kickOutGuest();
  }, []);

  const [userList, setUserList] = useState({
    columns: [
      {
        label: "ID",
        field: "user_id",
      },
      {
        label: "First Name",
        field: "first_name",
      },
      {
        label: "Last Name",
        field: "last_name",
      },
      {
        label: "Email",
        field: "email",
      },
      {
        label: "Institution",
        field: "institution",
      },
      {
        label: "Role",
        field: "role",
      },
    ],
    data: [],
  });

  const [user, setUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    institution: "",
  });
  const [roleList, setRoleList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showEditUser, setShowEditUser] = useState(false);
  const handleCloseEdit = () => {
    setShowEditUser(false);
    setUser({
      id: "",
      firstName: "",
      lastName: "",
      role: "",
      email: "",
      institution: "",
    });
  };

  const handleShowEdit = (row) => {
    getUserData(row.user_id);
    setShowEditUser(true);
  };

  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const handleCloseDelete = () => {
    setShowDeleteUser(false);
    setUser({
      id: "",
      firstName: "",
      lastName: "",
      role: "",
      email: "",
      institution: "",
    });
  };
  const handleShowDelete = (row) => {
    setShowDeleteUser(true);
    console.log(row);
    setUser({
      id: row.user_id,
      firstName: row.first_name,
      lastName: row.last_name,
    });
  };

  async function getUserList() {
    setLoading(true);
    await axios({
      method: "GET",
      url: "/admin/manage/users_list",
    }).then((res) => {
      setUserList({ ...userList, data: res.data });
    });
    setLoading(false);
  }

  function getUserData(id) {
    axios({
      method: "GET",
      url: "/admin/manage/users/" + id,
    })
      .then((res) => {
        setUser({
          id: res.data.user_id,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          email: res.data.email,
          institution: res.data.organization,
          role: res.data.role,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getRoleList() {
    axios({
      method: "GET",
      url: "/admin/manage/roles_list",
    })
      .then((res) => {
        let roles = [];
        res.data.forEach((role) => {
          roles.push({ label: role.role_name, value: role.role_name });
        });
        setRoleList(roles);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmitEdit() {
    setLoading(true);
    let userData = {
      userId: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      organization: user.institution,
    };
    console.log(userData);
    axios({
      method: "POST",
      url: "/admin/manage/users/update",
      data: userData,
    })
      .then((res) => {
        getUserList();
        toast.success(res.data.msg);
        handleCloseEdit();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }

  function handleSubmitDelete() {
    setLoading(true);
    let userData = { userId: user.id };
    console.log(userData);
    axios({
      method: "POST",
      url: "/admin/manage/users/delete",
      data: userData,
    })
      .then((res) => {
        getUserList();
        toast.success(res.data.msg);
        handleCloseDelete();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }

  useEffect(() => {
    getUserList();
    getRoleList();
  }, []);

  return (
    <>
      <RxnTable
        striped
        bordered
        hover
        data={userList}
        loading={loading}
        actions={{
          edit: true,
          editLogic: (row) => {
            handleShowEdit(row);
          },
          delete: true,
          deleteLogic: (row) => {
            handleShowDelete(row);
          },
        }}
      ></RxnTable>

      <Modal
        size="xl"
        backdrop="static"
        show={showEditUser}
        onHide={handleCloseEdit}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className="mb-3"
                    required
                    type="text"
                    placeholder="Enter first name"
                    value={user.firstName}
                    onChange={(e) =>
                      setUser({ ...user, firstName: e.target.value })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className="mb-3"
                    required
                    type="text"
                    placeholder="Enter last name"
                    value={user.lastName}
                    onChange={(e) =>
                      setUser({ ...user, lastName: e.target.value })
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter email"
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Institution</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter Institution"
                value={user.institution}
                onChange={(e) =>
                  setUser({ ...user, institution: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Select
                value={{ label: user.role, value: user.role }}
                options={roleList}
                className="mb-3"
                placeholder="Select Role"
                onChange={(e) => setUser({ ...user, role: e.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="primary"
            type="submit"
            onClick={() => handleSubmitEdit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        backdrop="static"
        show={showDeleteUser}
        onHide={handleCloseDelete}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete "{user.firstName} {user.lastName}"
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="danger"
            type="submit"
            onClick={() => handleSubmitDelete()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
