import React, { useEffect, useState } from "react";
import { Modal, Table, Card, ListGroup } from "react-bootstrap";
import axios from "axios";
import useToken from "../authentication/token";
import Base from "../layout/BaseTemplate";
import RxnTable from "../layout/components/RxnTable";

export default function UserRxnRegistrationList() {
  const { email } = useToken();

  // Modal States
  const [showView, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = (row) => {
    getUserRxnData(row.regId);
    setShowView(true);
  };
  const [loading, setLoading] = useState(false);

  const [userRxnList, setUserRxnList] = useState({
    columns: [
      {
        label: "Application ID",
        field: "regId",
      },
      {
        label: "Reaction Name",
        field: "rxnName",
      },
      {
        label: "Date Submitted",
        field: "dateSubmitted",
      },
      {
        label: "Status",
        field: "status",
      },
    ],
    data: [],
  });

  const [userRxnData, setUserRxnData] = useState({
    regId: "",
    rxnName: "",
    rxnDescription: "",
    rxnSmarts: "",
    rxnSmartsDescription: "",
    rxnTags: "",
    status: "",
    username: "",
    reactionId: "",
    email: "",
    dateSubmitted: "",
    synthons: [],
  });

  async function getUserRxns() {
    setLoading(true);
    await axios({
      method: "POST",
      url: "/rxnRegistration/userRegistrationList",
      data: { email: email },
    })
      .then((res) => {
        setUserRxnList({ ...userRxnList, data: res.data });
      })
      .catch((err) => {
        console.log(
          "Error fetching Pending User Reaction Registration List:",
          err
        );
      });
    setLoading(false);
  }

  function getUserRxnData(id) {
    axios({
      method: "GET",
      url: "/rxnRegistration/registrationDetail/" + id,
    })
      .then((res) => {
        setUserRxnData({
          regId: res.data.regId,
          rxnName: res.data.rxnName,
          rxnDescription: res.data.rxnDescription,
          rxnSmarts: res.data.rxnSmarts,
          rxnSmartsDescription: res.data.rxnSmartsDescription,
          rxnTags: res.data.rxnTags,
          status: res.data.status,
          username: res.data.username,
          reactionId: res.data.reactionId,
          email: res.data.email,
          dateSubmitted: res.data.dateSubmitted,
          synthons: res.data.synthons,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(userRxnData);
  }

  useEffect(() => {
    getUserRxns();
  }, []);

  return (
    <Base>
      <h1>My Reaction Registrations</h1>
      <RxnTable
        striped
        bordered
        hover
        data={userRxnList}
        loading={loading}
        actions={{
          view: true,
          viewLogic: (row) => handleShowView(row),
        }}
      />

      <Modal
        size="xl"
        backdrop="static"
        show={showView}
        onHide={handleCloseView}
      >
        <Modal.Header closeButton>
          <Modal.Title>{userRxnData.rxnName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>Reaction ID</th>
                <td>
                  {userRxnData.reactionId
                    ? userRxnData.reactionId
                    : "Pending ID"}
                </td>
              </tr>
              <tr>
                <th>Reaction Description</th>
                <td>{userRxnData.rxnDescription}</td>
              </tr>
              <tr>
                <th>Reaction SMARTS</th>
                <td>{userRxnData.rxnSmarts}</td>
              </tr>
              <tr>
                <th>SMARTS Description</th>
                <td>{userRxnData.rxnSmartsDescription}</td>
              </tr>
              <tr>
                <th>Reaction Tags</th>
                <td>{userRxnData.rxnTags}</td>
              </tr>
              <tr>
                <th>Date Submitted</th>
                <td>{userRxnData.dateSubmitted}</td>
              </tr>
            </tbody>
          </Table>
          {userRxnData.synthons
            ? userRxnData.synthons.map((synthon, index) => (
              <Card className="mb-3" key={"Synthon" + index}>
                <Card.Header>{synthon.synName}</Card.Header>
                <Card.Body>
                  <Card.Title>Inclusion Smarts</Card.Title>
                  <ListGroup variant="flush">
                    {synthon.inSmarts.map((insyn, index) => (
                      <ListGroup.Item key={"inSmarts" + index}>
                        {insyn}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  <Card.Title>Exclusion Smarts</Card.Title>
                  <ListGroup variant="flush">
                    {synthon.exSmarts.map((exsyn, index) => (
                      <ListGroup.Item key={"exSmarts" + index}>
                        {exsyn}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>
              </Card>
            ))
            : null}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Base>
  );
}
