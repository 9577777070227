import React, { useState, useEffect, forwardRef } from "react";
import _ from "lodash";
import "./MoleculeStructure.css";
import initRDKit from "../layout/utils/initRDKit";

//mostly taken from rdkit.js docs
const ReactionSVG = forwardRef((props, ref) => {

  const [rdkit, setRdkit] = useState(undefined)




  function drawSVG() {

    if (!rdkit) {
      return
    }
    const rxn = rdkit.get_rxn(props.rxnSmarts);
    const new_svg = rxn.get_svg();

    return (
      < div
        style={{ width: "100%", height: "100%" }
        }
        dangerouslySetInnerHTML={{ __html: new_svg }
        }
      ></div >
    )
  }

  useEffect(() => {
    if (rdkit === undefined) {
      initRDKit().then((rd) => {
        setRdkit(rd)
      })
    }
  }, []);

  return drawSVG();

})

export default ReactionSVG;

