import React, { useState, useEffect } from "react";
import axios from "axios";
import RxnTable from "../layout/components/RxnTable";
import { Button, Modal, Form } from "react-bootstrap";
import { IconAddOutline } from "../layout/components/SVGs";
import toast from "react-hot-toast";

// Authentication Check
import useToken from "../authentication/token";
import { useNavigate } from "react-router-dom";

export default function Synthons() {
  // Authentication Check
  const { token, role, verifyToken, kickOutGuest } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (role !== "admin") {
      navigate("/");
    }
    token ? verifyToken() : kickOutGuest();
  }, []);

  const [synthonList, setSynthonList] = useState({
    columns: [
      {
        label: "Synthon ID",
        field: "synthon_id",
      },
      {
        label: "Synthon Name",
        field: "synthon_name",
      },
      {
        label: "Synthon inclusion SMARTS",
        field: "synthon_smarts",
      },
    ],
    data: [],
  });

  // const [synthonInfo, setSynthonInfo] = useState({
  //   id: "",
  //   name: "",
  //   inSmarts: [],
  //   exSmarts: []

  // });

  const [synthon, setSynthon] = useState({
    id: "",
    name: "",
    inSmarts: "",
    exSmarts: ""
  });
  const [loading, setLoading] = useState(false);

  const [showAddSynthon, setshowAddSynthon] = useState(false);
  const handleAddClose = () => setshowAddSynthon(false);
  const handleShowAdd = () => {
    setshowAddSynthon(true);
  };

  const [showEditSynthon, setShowEditSynthon] = useState(false);
  const handleEditClose = () => {
    setShowEditSynthon(false);
  };
  const handleShowEdit = (row) => {
    let synthonData
    getSynthon(row.synthon_id);
    setShowEditSynthon(true);
    console.log(synthonData);
  };

  const [showDeleteRole, setShowDeleteRole] = useState(false);
  const handleDeleteClose = () => {
    setShowDeleteRole(false);
    setSynthon({ id: "", name: "", smarts: "" });
  };
  const handleShowDelete = (row) => {
    setShowDeleteRole(true);
    setSynthon({
      id: row.synthon_id,
      name: row.synthon_name,
    });
  };

  function getsynthonList() {
    axios({
      method: "GET",
      url: "/reactionClass/synthonList",
    })
      .then((res) => {
        setSynthonList({ ...synthonList, data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getSynthon(id) {
    axios({
      metthod: "GET",
      url : "/reactionClass/synthon/" + id
    }).then((res) => {
      setSynthon({
        id: res.data.synthon_id,
        name: res.data.synthon_name,
        inSmarts: res.data.inSMARTS[0], // there is only one inclusion SMARTS per synthon
        exSmarts: res.data.exSMARTS.join("\n")
      })
    }).catch((err) => {
      console.log(err);
    });
    }

  function handleSubmitCreate() {
    setLoading(true);
    let synthonData = {
      synthonName: synthon.name,
      synthonSmarts: synthon.inSmarts,
    };
    axios({
      method: "POST",
      url: "/admin/manage/roles/add",
      data: synthonData,
    })
      .then((res) => {
        getsynthonList();
        toast.success(res.data.msg);
        handleAddClose();
      })
      .catch((err) => {
        handleAddClose();
        toast.error(err.response.data.msg);
      });
    setLoading(false);
  }

  function handleSubmitEdit() {
    setLoading(true);
    let synthonData = {
      synthonId: synthon.id,
      synthonName: synthon.name,
      inSMARTS: [synthon.inSmarts],
      exSMARTS: synthon.exSmarts.split("\n"),
    };
    console.log(synthonData);
    axios({
      method: "POST",
      url: "/admin/manage/synthon/update",
      data: synthonData,
    })
      .then((res) => {
        getsynthonList();
        toast.success(res.data.msg);
        handleEditClose();
      })
      .catch((err) => {
        handleEditClose();
        toast.error(err.response.data.msg);
      });
    setLoading(false);
  }

  function handleSubmitDelete() {
    setLoading(true);
    let synthonData = {
      roleId: synthon.id,
    };
    axios({
      method: "POST",
      url: "/admin/manage/roles/delete",
      data: synthonData,
    })
      .then((res) => {
        getsynthonList();
        toast.success(res.data.msg);
        handleDeleteClose();
      })
      .catch((err) => {
        handleDeleteClose();
        toast.error(err.response.data.msg);
      });
    setLoading(false);
  }

  useEffect(() => {
    getsynthonList();
  }, []);

  return (
    <>
      <RxnTable
        striped
        bordered
        hover
        data={synthonList}
        token={token}
        actions={{
          add: true,
          addLogic: () => (
            <Button
              onClick={() => {
                handleShowAdd();
              }}
            >
              <IconAddOutline />
            </Button>
          ),
          edit: true,
          editLogic: (row) => {
            handleShowEdit(row);
          },
          delete: false,
          deleteLogic: (row) => {
            handleShowDelete(row);
          },
        }}
      ></RxnTable>

      {/* 
      ***
      Modal for creating roles
      ***
       */}
      <Modal
        size="xl"
        backdrop="static"
        show={showAddSynthon}
        onHide={handleAddClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter synthon name"
                onChange={(e) => {
                  setSynthon({ ...synthon, name: e.target.value });
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Role Description</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter role description"
                onChange={(e) =>
                  setSynthon({ ...synthon, description: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="primary"
            type="submit"
            onClick={() => handleSubmitCreate()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        backdrop="static"
        show={showEditSynthon}
        onHide={handleEditClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Synthon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Synthon Name</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter synthon name"
                value={synthon.name}
                onChange={(e) =>
                  setSynthon({ ...synthon, name: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Inclusion SMARTS</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter synthon SMARTS"
                value={synthon.inSmarts}
                onChange={(e) =>
                  setSynthon({ ...synthon, inSmarts: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Exclusion SMARTS</Form.Label>
              <Form.Control
                className="mb-3"
                as="textarea"
                rows={5}
                required
                type="text"
                placeholder="Enter synthon SMARTS"
                value={synthon.exSmarts}
                onChange={(e) =>
                  setSynthon({ ...synthon, exSmarts: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="primary"
            type="submit"
            onClick={() => handleSubmitEdit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        backdrop="static"
        show={showDeleteRole}
        onHide={handleDeleteClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete "{synthon.name}"
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="danger"
            type="submit"
            onClick={() => handleSubmitDelete()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
