import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Base from "../layout/BaseTemplate";
import { Table, Button, Card, Row, ListGroup, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft } from "../layout/components/SVGs";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import ReactionSVG from "../RDKIT/Reaction";

export default function ReactionDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [rxnDetail, setReactionDetail] = useState({
    rxnName: "",
    rxnDescription: "",
    rxnSmarts: "",
    rxnSmartsDescription: [],
    rxnId: "",
    rxnExternalIds: [],
    rxnTags: [],
  });
  const [synthonList, setSynthonList] = useState([]);

  function getReactionDetail() {
    axios({
      method: "GET",
      url: "/reactionClass/reaction/" + id,
    }).then((res) => {
      let splitDescription = [];
      if (res.data.smarts_description) {
        let rawDescription = res.data.smarts_description;
        splitDescription = rawDescription.split("\n");
      }

      res.data.synthons.map((synthons) => {
        setSynthonList((synthonList) => [...synthonList, synthons]);
        return "";
      });

      setReactionDetail({
        ...rxnDetail,
        rxnName: res.data.rxn_name,
        rxnDescription: res.data.description,
        rxnSmarts: res.data.smarts,
        rxnSmartsDescription: splitDescription,
        rxnId: res.data.rxn_id,
        rxnExternalIds: res.data.external_ids,
        rxnTags: res.data.tags,
      });
    });
  }

  function SynthonList() {
    const displaySynthons = synthonList.map((synthons, index) => (
      <Row key={"synthon" + index} className="mb-3">
        <Col>
          <Card>
            <Card.Header>
              Synthon {synthons.synthon_id} - {synthons.synthon_name}
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Card.Title>Inclusion Smarts</Card.Title>
                  <ListGroup variant="flush">
                    {synthons.synthon_inclusion_smarts.map((insyn, index) => {
                      return (
                        <ListGroup.Item key={"inSmarts" + index}>
                          {insyn}
                          &nbsp;
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              "https://arthorbb.docking.org/index.html#bb-instock-22q1/sma/s/" +
                              insyn
                            }
                          >
                            <Button variant="info" size="sm">
                              Arthor
                            </Button>
                          </a>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Col>
                <Col>
                  <Card.Title>Exclusion Smarts</Card.Title>
                  <ListGroup variant="flush">
                    {synthons.synthon_exclusion_smarts.map((exsyn, index) => {
                      return (
                        <ListGroup.Item key={"exSmarts" + index}>
                          {exsyn}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Col>

              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    ));

    return displaySynthons;
  }

  function DisplayImage() {
    const [imageSource, setImageSource] = useState(null);
    const localImageUrl = `/img/rxns/${id}.png`;
    const apiUrl = `https://smarts.plus/smartsview/download_rest?;vmode=0;textdesc=1;depsymbols=1;smartsheading=0;smarts=${encodeURIComponent(
      rxnDetail.rxnSmarts
    )}`;

    //Using img object, check whether img path is real by loading in the img
    const img = new Image();
    img.src = localImageUrl;

    //If img exist, then img src will the cached img
    img.onload = () => {
      setImageSource(localImageUrl);
    };

    //If no img in cache, then img src will be API call
    img.onerror = () => {
      setImageSource(apiUrl);
    };
    return (
      <Zoom>
        <img width={400} src={imageSource} alt={`${id}.png`} />
      </Zoom>
    );
  }

  useEffect(() => {
    getReactionDetail(id);
  }, []);

  return (
    <Base>
      <br />

      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Button
                className="mt-2"
                variant="outline-primary"
                onClick={() => {
                  navigate("/reactions");
                }}
              >
                <IconArrowLeft />
              </Button>
            </Col>
            <Col className="centerText mt-2">
              <h2>{rxnDetail.rxnName}</h2>
            </Col>
            <Col className="rightText mt-2">
              <Button
                target="_blank"
                href={
                  "https://tldr.docking.org/start/enumeration?rxn_id=" +
                  rxnDetail.rxnId
                }
              >
                Enumerate this Rxn on TLDR
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>

          <Row>
            <Col>
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <th>Reaction ID</th>
                    <td>{rxnDetail.rxnId}</td>
                  </tr>
                  <tr>
                    <th>Reaction Description</th>
                    <td>{rxnDetail.rxnDescription}</td>
                  </tr>
                  <tr>
                    <th>Reaction SMARTS</th>
                    <td>{rxnDetail.rxnSmarts}</td>
                  </tr>

                  <tr>
                    <th>SMARTS Description/References</th>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {rxnDetail.rxnSmartsDescription.map((description, index) => (
                        <li key={`description${index}`}><a target="__blank" href={description}>{description}</a></li>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th>Tag(s)</th>
                    <td>
                      {rxnDetail.rxnTags.map((tag, index) => (
                        <li key={"list" + index}>{tag.label}</li>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>

          </Row>

          <Row className="centerText">
            <Col>
              <ReactionSVG rxnSmarts={rxnDetail.rxnSmarts} />
            </Col>
          </Row>
          <Row className="centerText">
            <Col>
              <DisplayImage />
            </Col>
          </Row>

          <h2 style={{ display: "flex", justifyContent: "center" }}>
            Synthons
          </h2>
          <Row>
            <SynthonList />
          </Row>
        </Card.Body>
      </Card>
      <br />
    </Base>
  );
}
